<template>
<div class="hello">
    <h1>{{ msg }}</h1>
    <p style="color:#02b983"> {{ msg_en }}</p>
    <p>
        旨在为花卉爱好者和花艺师提供一个全面的数字平台，以深入探索、学习和分享关于鲜花及花艺的知识。
    </p>
    <p style="color:#42b983">
        It aims to provide a comprehensive digital platform for flower lovers and florists to explore, learn and share knowledge about flowers and floriculture
    </p>
    <br>
    <p>
        通过结合最新的技术和设计理念
    </p>
    <p>
        "Floral" 致力于在数字时代推广花卉文化
    </p>
    <p>
        使其不仅限于花卉专家和花艺师，也能吸引普通大众
    </p>
    <p>
        尤其是年轻一代
    </p>
    <hr/> <!-- This is the divider -->
    <div class="copyright">
      Copyright © 2020 - 2024 武汉银鲤创意科技有限公司. All Rights Reserved.
    </div>
    <p>
        <a href="https://beian.miit.gov.cn" target="_blank">鄂ICP备2024048917号-1</a>
      </p>
</div>
</template>

<script>
export default {
    name: 'HomeWelcome',
    props: {
        msg: String,
        msg_en: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.hello {
    padding-bottom: 50px;
}

hr {
    margin-top: 20px;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  }
  
  /* Style for the copyright text */
  copyright {
    text-align: center;
    margin-top: 10px;
    font-size: 0.85em;
    color: #666;
  }
</style>
